export const API_URL = process.env.REACT_APP_API_ENDPOINT
export const API_AUTH_URL = process.env.REACT_APP_AUTH_API_ENDPOINT
export const API_STORAGE_URL = process.env.REACT_APP_STORAGE_API_ENDPOINT

export const API_LOG_IN = '/api/Auth/Login'
export const API_LOG_OUT = '/api/Auth/LogOut'
export const API_REFRESH_TOKEN = '/api/Auth/refreshToken'
export const API_FORGOT_PASSWORD = '/api/Auth/forgotPassword'
export const API_RESET_PASSWORD = '/api/Auth/resetPassword'
export const API_ACTIVATE_USER_NO_PASSWORD = '/api/Auth/activateUserNoPassword'
export const API_IMPERSONATE_USER = '/api/Users/impersonate'

export const API_CHANGE_PASSWORD = '/api/Auth/changePassword'

export const API_CREATE_INHOUSE_COMPANY = '/api/Companies/inHouse'
export const API_CREATE_OUTHOUSE_COMPANY = '/api/Companies/outHouse'
export const API_UPDATE_INHOUSE_COMPANY = '/api/Companies/inHouse'
export const API_UPDATE_OUTHOUSE_COMPANY = '/api/Companies/outHouse'

export const API_CREATE_BANK = '/api/Bank'
export const API_UPDATE_BANK = '/api/Bank'
export const API_GET_BANK = '/api/Bank/:id'
export const API_GET_BANKS = '/api/Bank'

export const API_GET_COMPANY = '/api/Companies/:id'
export const API_GET_COMPANIES = '/api/Companies'
export const API_GET_COMPANIES_LIGHT = '/api/Companies/light'

export const API_GET_STATES = '/api/States'
export const API_GET_INDUSTRIES = '/api/industries'

export const API_GET_ISO = '/api/ISO'
export const API_GET_ISO_LIGHT = '/api/ISO/light'
export const API_CREATE_ISO = '/api/ISO'
export const API_GET_ISO_BY_ID = '/api/ISO/:id'

export const API_GET_SYNDICATORS = '/api/Syndicator'
export const API_CREATE_SYNDICATOR = '/api/Syndicator'
export const API_UPDATE_SYNDICATOR = '/api/Syndicator'
export const API_GET_SYNDICATOR = '/api/Syndicator/:id'
export const API_REQUEST_INFO = '/api/Syndicator/requestInfo'
export const API_REQUEST_COOPERATION = '/api/Syndicator/requestCooperation'
export const API_EDIT_ISO = '/api/ISO'
export const API_SYNDICATOR_LIGHT = '/api/Syndicator/light'
export const API_SYNDICATOR_REPORT = '/api/Syndicator/report'
export const API_SYNDICATOR_REPORT_TOTAL = '/api/Syndicator/reportTotal'
export const API_SYNDICATOR_SET_LIMIT = '/api/Syndicator/setLimit'
export const API_GET_PORTFOLIO_SUMMARY = '/api/Syndicator/summary'
export const API_GET_SYNDICATED_DEALS = '/api/Syndicator/deals'
export const API_GET_SYNDICATED_DEAL_STATS = '/api/Syndicator/dealStatistic/:id'
export const API_GET_PORTFOLIO_SUMMARY_TOTAL = '/api/Syndicator/summary/total'

export const API_GET_REP_LIST = '/api/Representative'
export const API_GET_REP_LIGHT = '/api/Representative/light'
export const API_CREATE_REP = '/api/Representative'
export const API_EDIT_REP = '/api/Representative'
export const API_GET_REP_BY_ID = '/api/Representative/:id'
export const API_GET_REP_PERFORMANCE = '/api/Representative/performance'
export const API_GET_REP_FUNDERS = '/api/Representative/funders'
export const API_GET_REP_APPROVED_FUNDERS = '/api/Representative/approvedFunders'
export const API_PUT_REQUEST_ANONYMOUS = '/api/Representative/requestByToken'
export const API_SET_MANAGEMENT_FEE = '/api/Representative/setManagementFee'

export const API_GET_VENDOR_LIST = '/api/Vendor'
export const API_EDIT_VENDOR = '/api/Vendor'
export const API_CREATE_VENDOR = '/api/Vendor'
export const API_GET_VENDOR_BY_ID = '/api/Vendor/:id'
export const API_GET_VENDOR_LIGHT_LIST = '/api/Vendor/light'

export const API_CREATE_CLIENT = '/api/Clients'
export const API_GET_CLIENTS_LIST = '/api/Clients'
export const API_GET_CLIENTS_LIGHT = '/api/Clients/light'
export const API_EDIT_CLIENT = '/api/Clients'
export const API_GET_CLIENT_BY_ID = '/api/Clients/:id'
export const API_INVITE_CLIENT = '/api/Clients/invite'
export const API_ADD_EXISTING_CLIENT = '/api/Clients/addExisting'
export const API_GET_CLIENTS_BY_USER = '/api/Clients/byUser/:id'
export const API_GET_CLIENT_BANK_ACCOUNTS = '/api/Clients/bankAccounts'
export const API_CREATE_CLIENT_BANK_ACCOUNT = '/api/Clients/bankAccounts'
export const API_EDIT_CLIENT_BANK_ACCOUNT = '/api/Clients/bankAccounts'
export const API_DELETE_CLIENT_BANK_ACCOUNT = '/api/Clients/bankAccounts/:clientId/:bankAccountId'
export const API_SET_MAIN_CLIENT_BANK_ACCOUNT = '/api/Clients/bankAccounts/setAsMain'
export const API_CLIENTS_ME = '/api/Clients/me'
export const API_CLIENTS_EQUIFAX = '/api/Clients/equifax'
export const API_CLIENTS_EXPERIAN = '/api/Clients/experian'
export const API_CLIENTS_PRBC = '/api/Clients/prbc'
export const API_CLIENTS_ENHANCE_SEARCH = '/api/Clients/enhancedPeopleSearch'

export const API_CREATE_OUTHOUSE_DEAL = '/api/Deal/outHouse'
export const API_EDIT_OUTHOUSE_DEAL = '/api/Deal/outHouse'
export const API_GET_DEAL = '/api/Deal/:id'
export const API_DELETE_DEAL = '/api/Deal/:id'
export const API_GET_DEALS_LIST = '/api/Deal'
export const API_GET_DEALS_STATS = '/api/Deal/statistic'
export const API_GET_DEALS_STATS_FOR_CHARTS = '/api/Deal/charts'
export const API_GET_DEALS_QUES = '/api/Deal/queue'
export const API_CHANGE_DEALS_STATUS = '/api/Deal/status'
export const API_SET_LENDER = '/api/Deal/lenders'
export const API_EDIT_LENDER = '/api/Deal/lenders'
export const API_DELETE_LENDER = '/api/Deal/lenders/:lenderId/:companyId'
export const API_GET_LENDERS_LIST = '/api/Deal/lenders/:id'
export const API_GET_DEAL_LIGHT_LIST = '/api/Deal/light'
export const API_REFI_DEAL = '/api/Deal/refi'
export const API_GET_REFI_DEALS = '/api/Deal/getDealsForRefi/:clientId'
export const API_CREATE_SCHEDULE = '/api/Deal/schedules'
export const API_CREATE_BASE_DEAL = '/api/Deal/base'
export const API_EDIT_BASE_DEAL = '/api/Deal/base'
export const API_GET_REP_PORTFOLIO_SUMMARY = '/api/Deal/portfolioSummary'
export const API_SET_TO_DEFAULT = '/api/Deal/setDealToDefault'
export const API_STOP_CLIENT_FEES = '/api/Deal/stopClientFees/:id'
export const API_START_CLIENT_FEES = '/api/Deal/startClientFees/:id'
export const API_REFUND_DEAL = '/api/Deal/getRefund'
export const API_EXTEND_DEAL = '/api/Deal/extendDeal'
export const API_SEND_TRANSACTION_HISTORY_TO_EMAIL = '/api/Deal/sendTrsHistortyEmail/:dealId'
export const API_GET_SCHEDULES_BY_DEAL = '/api/Deal/schedules/:dealId'
export const API_PREP_FUNDING_EMAIL = '/api/Deal/sendPrepFundingEmail/:dealId'
export const API_READY_TO_FUND_EMAIL = '/api/Deal/sendReadyToFundEmail/:dealId'
export const API_SUBMIT_DEAL = '/api/Deal/submit'
export const API_PAY_OFF = '/api/Deal/payOff/:id'
export const API_ZERO_BALANCE = '/api/Deal/zeroBalance/:id'
export const API_DELETE_ALL_SCHEDULE = '/api/Deal/schedules/all/:dealId'
export const API_DELETE_SCHEDULE = '/api/Deal/schedules/:scheduleId'
export const API_GET_REVERSE_SCHEDULES_BY_DEAL = '/api/Deal/reverseSchedules/:dealId'
export const API_CREATE_REVERSE_SCHEDULE = '/api/Deal/reverseSchedules'
export const API_DELETE_REVERSE_SCHEDULE = '/api/Deal/reverseSchedules/:scheduleId'
export const API_DELETE_ALL_REVERSE_SCHEDULE = '/api/Deal/reverseSchedules/all/:dealId'
export const API_CALCULATE_PAYMENT_SCHEDULE = '/api/Deal/calculatePaymentSchedule'
export const API_GET_FUNDER_LENDERS = '/api/Deal/funderLenders/:dealId'
export const API_CLAWBACK = '/api/Deal/clawback/:dealId'
export const API_RETURN_CLAWBACK = '/api/Deal/returnClawback/:dealId'
export const API_ISO_CLAWBACK = '/api/Deal/isoClawback/:dealId'
export const API_RETURN_ISO_CLAWBACK = '/api/Deal/returnIsoClawback/:dealId'
export const API_MOVE_TO_DOCUSIGN = '/api/Deal/moveToDocusign/:id'
export const API_DEAL_GET_MERCHANT_CLIENTS = '/api/Deal/merchantClients/:dealId'
export const API_DEAL_UPDATE_MERCHANT_CLIENTS = '/api/Deal/merchantClients'

export const API_CREATE_INTHOUSE_DEAL = '/api/Deal/inHouse'
export const API_EDIT_INTHOUSE_DEAL = '/api/Deal/inHouse'

export const API_GET_AUDIT_LOG = '/api/HistoricalData'
export const API_GET_COMMENTS_LOG = '/api/HistoricalData/commentsLog'
export const API_CREATE_COMMENT_LOG = '/api/HistoricalData/commentsLog'

// export const API_GET_CLIENTS_LIST = '/api/Clients'
// export const API_GET_CLIENTS_LIGHT = '/api/Clients/light'

export const API_CREATE_OWNER = '/api/Clients/owners'
export const API_EDIT_OWNER = '/api/Clients/owners'
export const API_DELETE_OWNER = '/api/Clients/owners/:id'

export const API_CREATE_NOTIFICATION = '/api/Notification'
export const API_GET_NOTIFICATION = '/api/Notification/:id'
export const API_GET_SENT_NOTIFICATION = '/api/Notification/sent'
export const API_MARK_NOTIFS_AS_READ = '/api/Notification/allRead'
export const API_GET_ALL_RECEIVED_NOTIFICATION = '/api/Notification/allReceived'
export const API_GET_NEW_NOTIFICATION_COUNT = '/api/Notification/count'
export const API_GET_ALL_NEW_NOTIFICATION = '/api/Notification/newReceived'

export const API_GET_USERS_LIGHT = '/api/UsersInfo/users'
export const API_GET_ROLES_LIGHT = '/api/UsersInfo/roles'
export const API_GET_ROLES = '/api/UserRoles'
export const API_ADD_ROLE = '/api/UserRoles'
export const API_DELETE_ROLE = '/api/UserRoles/:id'
export const API_GET_USERS = '/api/Users'
export const API_SET_USER_ROLES = '/api/UserRoles/setUserRoles'
export const API_GET_CURRENT_USER = '/api/Users/me'
export const API_GET_EMAIL_BY_TOKEN = '/api/Users/getByToken/:token'
export const API_UPDATE_USER = '/api/Users/updateUser'

export const API_EXPORT_COMPANIES = '/api/Export/companies'
export const API_EXPORT_PAYMENT_REPORTS = '/api/Export/paymentReport'
export const API_EXPORT_TRANSACTIONS = '/api/Export/activity'
export const API_EXPORT_ISOS = '/api/Export/isos'
export const API_EXPORT_CLIENTS = '/api/Export/clients'
export const API_EXPORT_VENDORS = '/api/Export/vendors'
export const API_EXPORT_REP = '/api/Export/representatives'
export const API_EXPORT_SYNDICATORS = '/api/Export/syndicators'
export const API_EXPORT_SYNDICATOR_REPORT = '/api/Export/syndicator'
export const API_EXPORT_SYNDICATOR_SUMMARY = '/api/Export/syndicators/summary'
export const API_EXPORT_REP_PORTFOLIO_SUMMARY = '/api/Export/portfolioSummary'
export const API_EXPORT_DEAL_PAY_HISTORY = '/api/Export/dealTrsHistory'
export const API_EXPORT_DEAL_PAY_HISTORY_CONDENSED = '/api/Export/dealTrsHistoryCondensed'
export const API_EXPORT_DEAL_PAYMENTS = '/api/Export/dealPayments'

export const API_EXPORT_DEALS = '/api/Export/deals'

export const API_GET_UPLOAD_URL = '/api/Storage/uploadUrl'
export const API_DOWNLOAD_URL = '/api/Storage/downloadUrl'
export const API_CONNECT_DEAL_DOCUMENTS_TO_DEAL = '/api/Storage/dealDocuments'
export const API_CONNECT_CLIENT_DOCUMENTS_TO_DEAL = '/api/Storage/dealDocuments/:id/copy'
export const API_CONNECT_CLIENT_DOCUMENTS_TO_CLIENT = '/api/Storage/clientDocuments'
export const API_CONNECT_ISO_DOCUMENTS_TO_ISO = '/api/Storage/isoDocuments'
export const API_CONNECT_SYNDICATOR_DOCUMENTS_TO_SYNDICATOR = '/api/Storage/syndicatorDocuments'
export const API_CONNECT_REP_DOCUMENTS_TO_REP = '/api/Storage/representativeDocuments'
export const API_CONNECT_VENDOR_DOCUMENTS_TO_VENDOR = '/api/Storage/vendorDocuments'
export const API_DELETE_DEAL_DOCUMENT = '/api/Storage/dealDocuments/:id'
export const API_DELETE_ISO_DOCUMENT = '/api/Storage/isoDocuments/:id'
export const API_DELETE_REP_DOCUMENT = '/api/Storage/representativeDocuments/:id'
export const API_DELETE_SYNDICATOR_DOCUMENT = '/api/Storage/syndicatorDocuments/:id'
export const API_DELETE_CLIENT_DOCUMENT = '/api/Storage/clientDocuments/:id'
export const API_DELETE_VENDOR_DOCUMENT = '/api/Storage/vendorDocuments/:id'
export const API_UPLOAD_COMPANY_LOGO = '/api/Storage/companyLogo'
export const API_DELETE_NACHA_DOC = '/api/Storage/nachaDocuments/:id'

export const API_GET_VENDOR_FEE = '/api/VendorFee'
export const API_CREATE_VENDOR_FEE = '/api/VendorFee'
export const API_SAVE_VENDOR_FEE = '/api/VendorFee/save'
export const API_GET_PREVIEW_LEGAL_FEE = '/api/VendorFee/preview'

export const API_GET_ACH = '/api/Ach'
export const API_EDIT_ACH = '/api/Ach'
export const API_STOP_ACH = '/api/Ach/stop'
export const API_GET_NACHA_FILES = '/api/Ach/nachaFiles'
export const API_PROCESS_CREDIT = '/api/Ach/processCredits'
export const API_PROCESS_DEBITS = '/api/Ach/processDebits'
export const API_PROCESS_FEES = '/api/Ach/processFees'
export const API_CANCEL_PAYMENT = '/api/Ach/cancel/:id'
export const API_DUPLICATE_PAYMENT = '/api/Ach/duplicate/:id'
export const API_DUPLICATE_ALL_PAYMENTS = '/api/Ach/duplicate'

export const API_GET_TRANSACTIONS_LIST = '/api/Transaction/activity'
export const API_CREATE_TRANSACTION = '/api/Transaction'

export const API_GET_WITHDRAWAL = '/api/Transaction/withdrawal'
export const API_CHANGE_TRANSACTION_STATUS = '/api/Transaction/status'
export const API_GET_SUMMARY_WITHDRAWAL = '/api/Transaction/withdrawal/summary'
export const API_GET_DEAL_PAYMENTS = '/api/Transaction/dealPayments'
export const API_CREATE_DEAL_PAYMENT = '/api/Transaction/dealPayment'
export const API_EDIT_TRANSACTION = '/api/Transaction'
export const API_APPLY_COMMISSION = '/api/Transaction/applyCommission/:id'
export const API_APPLY_PSF = '/api/Transaction/applyPsf/:id'
export const API_GET_TODAYS_PAYMENT_REPORT_LIST = '/api/Transaction/report'

export const API_GET_HOLIDAYS = '/api/Holiday'
export const API_CREATE_HOLIDAY = '/api/Holiday'
export const API_DELETE_HOLIDAY = '/api/Holiday/:id'

export const API_IS_FEATURE_ENABLED = '/api/FeatureFlag'

export const GOOGLE_MAPS_API_GET_GEOCODE = 'https://maps.googleapis.com/maps/api/geocode/json'
